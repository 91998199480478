<template>
  <div>
    <v-container
      style="text-align:center;justify-content: center;padding:2em; margin-top:5em;"
    >
      <v-row style="margin-bottom:3em;">
        <v-col>
          <h1 style="color:#330667"><i>FALE CONOSCO :</i></h1>
        </v-col>
      </v-row>
      <v-container>
        <v-row style="justify-content: center;">
          <v-col
            style="color:#330667;font-weight: bold;text-align: center;padding: 3%;"
          >
            <v-icon
              style="color:#330667;margin-right:2%;font-size: 60px;"
              x-large
            >
              mdi-whatsapp
            </v-icon>
            <br />
            <br />
            +55 11 99523 0937
          </v-col>
          <v-col
            style="color:#330667;font-weight: bold;text-align: center;padding: 3%;"
          >
            <v-icon
              style="color:#330667;margin-right:2%;font-size: 60px;"
              x-large
            >
              mdi-at
            </v-icon>
            <br /><br />
            contato@spektraconsultoria.com.br
          </v-col>
        </v-row>
      </v-container>
      <v-container fluid>
        <v-row>
          <v-col style="padding:6%;">
            <v-card style="text-align:center;padding:4%;font-size: larger">
              <v-form>
                <v-row style="margin-bottom:5%;">
                  <v-col>
                    <h4 style="color:#330667">E-MAIL</h4>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="nome"
                      :rules="nameRules"
                      label=" Seu Nome"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model="email"
                      :rules="emailRules"
                      label="E-mail"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      v-model="telefone"
                      label="Telefone"
                      v-mask="'(##) #####-####'"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="empresa"
                      :rules="empresaRules"
                      label="Nome da Empresa"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="assunto"
                      :rules="assuntoRules"
                      label="Assunto"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <br /><br />

                <v-textarea
                  outlined
                  v-model="menssage"
                  :rules="menssageRules"
                  placeholder="Digite aqui sua mensagem..."
                  auto-grow
                  autocomplete="email"
                ></v-textarea>

                <v-checkbox
                  v-model="allowSpaces"
                  label="Informamos que coletamos seus dados pessoais (Nome, Telefone, E-mail) para fins de manter contato com a empresa Spektra Consultoria. Os seus dados pessoais serão armazenados e preservados por um período indeterminado; os dados dos clientes são registrados no sistema e podem ser anonimizados a qualquer momento, desde que o cliente solicite."
                  required
                ></v-checkbox>

                <v-btn
                  class="mr-4"
                  style="margin-top:3%;background-color:#330667;color:white"
                  value="Send"
                  @click="sendEmail"
                >
                  Enviar
                </v-btn>
              </v-form>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    <b-container class="mt-5 text-center">
      <v-row>
        <v-col>
          <b-link
            target="_blank"
            href="https://www.instagram.com/spektraconsultoria/?hl=pt-br"
            ><i
              class="fab fa-instagram fa-2x ml-1"
              style="color:#330667!important;font-size:4em"
            ></i
          ></b-link>
          <b-link
            target="_blank"
            href="https://www.linkedin.com/company/spektra-consultoria/"
            ><i
              class="fab fa-linkedin fa-2x ml-4"
              style="color:#330667!important;font-size:4em"
            ></i
          ></b-link>
        </v-col>
      </v-row>
    </b-container>
    <v-container class="text-center">
      <div style="opacity:0.3; margin-bottom:5em;margin-top:6em">
        <img
          class="text-center mb-4"
          src="@/assets/logo-spektra.png"
          style="width:8%;"
        />
        <p>© 2020 SPEKTRA Design by W.P.I</p>
      </div>
    </v-container>
  </div>
</template>

<script>
import emailjs from "emailjs-com";
export default {
  name: "Contact",
  data() {
    return {
      allowSpaces: false,
      nome: "",
      email: "",
      telefone: "",
      empresa: "",
      assunto: "",
      menssage: "",
      emailRules: [
        (v) => !!v || "E-mail é obrigatório",
        (v) => /.+@.+/.test(v) || "E-mail inválido",
      ],
      nameRules: [
        (v) => !!v || "Campo Obrigatório",
        (v) =>
          v.length <= 50 || "Campo não pode ser maior do que 50 caracteres",
      ],
      empresaRules: [
        (v) => !!v || "Campo Obrigatório",
        (v) =>
          v.length <= 50 || "Campo não pode ser maior do que 50 caracteres",
      ],
      assuntoRules: [
        (v) => !!v || "Campo Obrigatório",
        (v) =>
          v.length <= 50 || "Campo não pode ser maior do que 50 caracteres",
      ],
      menssageRules: [
        (v) => !!v || "Campo Obrigatório",
        (v) =>
          v.length <= 500 || "Campo não pode ser maior do que 500 caracteres",
      ],
    };
  },

  methods: {
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    sendEmail: async function() {
      await emailjs
        .send(
          "emailSpektra",
          "template_spektra",
          {
            from_name: this.nome,
            from_empresa: this.empresa,
            from_message: this.menssage,
            from_fone: this.telefone,
            reply_to: this.email,
            user_mail: this.email,
            customer_name: this.nome,
          },
          "user_h2KlGDqlHtgptROpdRbWS"
        )
        .then(
          (result) => {
            alert("E-mail enviado com sucesso, obrigada!");
            console.log("SUCCESS!", result.status, result.text);
          },
          (error) => {
            console.log("FAILED...", error);
          }
        );
    },
  },
};
</script>
